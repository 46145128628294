import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getCookie, removeCookie } from '../shared/libs/cookies';
import useGTM from '../shared/customHooks/useGTM';
import Layout from '../components/Layout';
import Home from '../components/Home';
import * as apiService from '../shared/api.service';
import { createPixel } from '../shared/pixels';

const LandingTemplate = ({ data }) => {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const [user, setUser] = useState({});
  useGTM({ pageTitle: 'Inicio', pageContent: 'form', viewContent: 'home', slug: 'calculator' });

  useEffect(() => {
    const getUser = async () => {
      const { data, status } = await apiService.getUser();
      if (status === 200 || status === 304) {
        setUser(data);
      } else {
        removeCookie('jwt');
      }
    };

    if (getCookie('jwt')) {
      getUser();
    }
    createPixel();
  }, []);

  return (
    <Layout user={user}>
      <Home
        user={user}
        details={{
          title: <span dangerouslySetInnerHTML={{ __html: html }} />,
          image: frontmatter.image,
        }}
      />
    </Layout>
  );
};

LandingTemplate.propTypes = {
  data: PropTypes.object,
};

export default LandingTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        image
      }
    }
  }
`;
